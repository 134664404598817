import React from "react"
import Layout from "../components/layout/Layout"
import Seo from "../components/SEO"
import { Box } from "@mui/material"
import { Link } from "gatsby"

const PrivateLessons = () => {
  return (
    <Layout>
      <Seo
        title="Contact"
        desc="Do you have any questions or requests? Please contact us."
      />
      <Box
        component="main"
        sx={{
          p: {
            xs: "2rem",
            sm: "3rem",
          },
          minHeight: "50vh",
          textAlign: "center",
        }}
      >
        <h2 className="underline" style={{ marginBottom: "var(--s2)" }}>
          Private Lessons
        </h2>
        <div>
          <h4 style={{ marginBottom: "var(--s1)" }}>
            Do you have difficulties in your pronunciation?
          </h4>
          <p>Xin chào, I'm Hà, and I'm here to help! </p>
          <p>
            I've been teaching English and Vietnamese since 2015. I also have
            IELTS 8.0, so it's easy for us to communicate.
          </p>
          <p>Hop on a call with me to practice and improve your Vietnamese!</p>
          <p>
            You can contact me to schedule your lessons{" "}
            <Link to="/contact">
              <span style={{ color: "var(--clr-blue)" }}>here.</span>
            </Link>
          </p>
        </div>
      </Box>
    </Layout>
  )
}

export default PrivateLessons
